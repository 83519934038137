// normalize CSS across browsers
import 'normalize.css'

// custom CSS styles
import './src/styles/fonts.css'
import './src/styles/global.css'


// in gastby-browser.js
const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
  }) => {
    // HACK: for scrolling to top on page change
    setTimeout(() => {
      // set position to 0,0 no animation
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' })
    }, 1)
    return false
}   

export { shouldUpdateScroll }