exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-connectors-[slug]-js": () => import("./../../../src/pages/connectors/[slug].js" /* webpackChunkName: "component---src-pages-connectors-[slug]-js" */),
  "component---src-pages-connectors-index-js": () => import("./../../../src/pages/connectors/index.js" /* webpackChunkName: "component---src-pages-connectors-index-js" */),
  "component---src-pages-consulting-js": () => import("./../../../src/pages/consulting.js" /* webpackChunkName: "component---src-pages-consulting-js" */),
  "component---src-pages-contact-2-js": () => import("./../../../src/pages/contact-2.js" /* webpackChunkName: "component---src-pages-contact-2-js" */),
  "component---src-pages-contact-3-js": () => import("./../../../src/pages/contact-3.js" /* webpackChunkName: "component---src-pages-contact-3-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-data-processing-addendum-js": () => import("./../../../src/pages/data-processing-addendum.js" /* webpackChunkName: "component---src-pages-data-processing-addendum-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-free-trial-js": () => import("./../../../src/pages/free-trial.js" /* webpackChunkName: "component---src-pages-free-trial-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nonprofit-js": () => import("./../../../src/pages/nonprofit.js" /* webpackChunkName: "component---src-pages-nonprofit-js" */),
  "component---src-pages-platform-js": () => import("./../../../src/pages/platform.js" /* webpackChunkName: "component---src-pages-platform-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-private-equity-js": () => import("./../../../src/pages/private-equity.js" /* webpackChunkName: "component---src-pages-private-equity-js" */),
  "component---src-pages-professional-services-js": () => import("./../../../src/pages/professional-services.js" /* webpackChunkName: "component---src-pages-professional-services-js" */),
  "component---src-pages-sla-js": () => import("./../../../src/pages/sla.js" /* webpackChunkName: "component---src-pages-sla-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-video-js": () => import("./../../../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */),
  "component---src-pages-whitepaper-js": () => import("./../../../src/pages/whitepaper.js" /* webpackChunkName: "component---src-pages-whitepaper-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-landing-pages-js": () => import("./../../../src/templates/landingPages.js" /* webpackChunkName: "component---src-templates-landing-pages-js" */)
}

